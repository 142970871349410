import React, { useState, useEffect } from 'react';
import { modifiedTokenHash } from '../../Utils/utils';

const SearchComponent = ({ data, readOnly, autoFocus }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);

  // Debounce search input by 300ms (to prevent searching on every keystroke)
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery.length > 0) {
        handleSearch();
      } else {
        setFilteredResults([]);
      }
    }, 100); // Adjust debounce time as necessary
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  useEffect(() => {
    const input = document.getElementById("search-box");
    if(autoFocus && input) {
      setTimeout(() => {
        input.focus();
      }, 100)
    }
  }, [])

  const onInputChange = (value) => {
    handleSearch();
    setSearchQuery(value);
    //sendSearchParameter(value);
  }

  const handleSearch = () => {
    const query = searchQuery.toLowerCase();
    const filtered = data.filter(item => item.TokenName.trim().toLowerCase().includes(query) || item.TokenSymbol.trim().toLowerCase().includes(query) || item.PairAddress.trim().toLowerCase().includes(query)
      //item.TokenHash.toLowerCase().includes(query)
    );
    setFilteredResults(filtered);
  };

  const navigateTo = (urlTokenHash) => {
    window.location.href = `/callers/${urlTokenHash}`;
  }

  return (
    <div className='search-bar'>
      <input
        id="search-box"
        type="text"
        placeholder="Search token name, pair or hash.."
        value={searchQuery}
        onChange={(e) => onInputChange(e.target.value)}
        readOnly={readOnly}
        autoFocus={autoFocus}
      />
      <div className='search-results'>
        {filteredResults && filteredResults.length > 0 ? (
          <ul className='result-list'>
            {filteredResults.map((item, i) => {
              return (
              <li key={i} onClick={()=> navigateTo(item.TokenHash)}>
                <div>
                  <h3>{item.TokenSymbol}</h3>
                  <p>{item.TokenName} <br /><span>{modifiedTokenHash(item.TokenHash)}</span></p>
                </div>
                <img src="/img/search_icon.svg" />
              </li>
              )
            })}
          </ul>
        ) : searchQuery && (
          <p>No results found for "{searchQuery}".</p>
        )}
        </div>
      
    </div>
  );
};

// Usage Example
// Assuming `data` is passed as a prop, which can be an array of objects containing title and description

// const mockData = [
//   { id: 1, title: "React Tutorial", description: "Learn the basics of React.js." },
//   { id: 2, title: "JavaScript Tips", description: "Best practices for JavaScript development." },
//   { id: 3, title: "CSS Grid Layout", description: "Mastering CSS Grid for responsive designs." }
// ];

// <SearchComponent data={mockData} />

export default SearchComponent;
