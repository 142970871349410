import React, { useState, useEffect } from "react";
import * as httpService from '../services/HTTPService';
import { useLocation } from 'react-router-dom';
import ChartComponent from "./UI/StockChart";
import ReactGrid from "./React-Grid";
import { createGridColumns } from "../Utils/utils";
import SvgIcon from "./UI/SVGIcon";
import HeaderSocialLinks from "./HeaderSocialLinks";
import { modifiedTokenHash } from "../Utils/utils";

const CallersList = () => {
    const location = useLocation();
    const [resData, setResData] = useState({});
    const socialLinksArray = [
        {
            link: '/',
            icon: '/img/social/header-social/X_20x20.svg',
            class: 'tw-link',
            name: 'Twitter',
            text: 'Follow us on Twitter to stay in the loop with real-time crypto trends, Shillguard news, and engaging discussions.',
        },
        {
            link: '/',
            icon: '/img/social/header-social/Telegram_20x20.svg',
            class: 'tel-link',
            name: 'Telegram',
            text: 'Connect with us on Telegram, your go-to hub for crypto calls, insightful discussions, and Shillguard announcements',
        },
        {
            link: '/',
            icon: '/img/social/header-social/Discord_20x20.svg',
            class: 'dis-link',
            name: 'Discord',
            text: 'Join our Discord community for live chats, AMAs, and vibrant discussions with fellow crypto enthusiasts.',
        },
        {
            link: '/',
            icon: '/img/social/header-social/IG_20x20.svg',
            class: 'in-link',
            name: 'Instagram',
            text: 'Follow us on Instagram for a visual journey into the Shillguard ecosystem.',
        },
    ]
    // TODO: replace resSocialData from backend method when it's done
    const [resSocialData, setResSocialData] = useState(socialLinksArray);
    const tName = window.location.href.split('/').pop();
    const decodedUrlTName = decodeURIComponent(tName);
    const searchParam  = location.state ? location.state.callGroup : decodedUrlTName;

    const CallGroupHtml = (props) => {
        let imageBase64str;
        const folderName = '/callgrimages/';
        let imgUrl = '';
        if(props.data.CallGroupImage || props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage) {
            let imgSrc = props.data.CallGroupImage || props.data.CryptoGroupImage || props.data.Image || props.data.TokenImage;
            imageBase64str = imgSrc.replace(/(\r\n|\n|\r)/gm, "");
            if(imageBase64str && imageBase64str !== '' && !imageBase64str.startsWith('http')) {
                imageBase64str = "data:image/png;base64, " + imgSrc.replace(/(\r\n|\n|\r)/gm, "");
            }
            if(imageBase64str.startsWith('http')) {
                imageBase64str = imgSrc;
            }
            if(imgSrc === null || imageBase64str.startsWith('https://shillguard-001')) {
                // TOP 5 Callers
                const imgName = imgSrc.split('/').pop();
                const img = folderName + imgName;
                imageBase64str = img;
            }
        }
        
        return (
            <div>
                {props !== undefined ? (
                    <div className={props.CryptoGroupImage ? 'flex-box custom-cell rounded-img' : 'custom-cell rounded-img'} >
                        {imageBase64str? (
                            <div className="image-name">
                                <img height={46} src={imageBase64str} alt="CallGroupImage" />
                                <span>{props.data.NameOfCallGroup}</span>
                            </div>
                        ) : null}
                        {/* {props.data.Image ? (<img height={27} src={props.data.Image} alt="CallGroupImage" />) : null} */}
                    </div>
                ) : null}
            </div>
        )
    }

    const RedOrGreenColoringCallEf = (props) => {
        const label = props.data.CallEfc;
        const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
        return (
            <div>
                {props !== undefined ? (
                    <div className={txtColor}>{props.data.CallEfc}</div>
                ) : null}
            </div>
        )
    }

    const RedOrGreenColoringTrace= (props) => {
        const label = props.data.Trace;
        const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
        return (
            <div>
                {props !== undefined ? (
                    <div className={txtColor}>{props.data.Trace}</div>
                ) : null}
            </div>
        )
    }

    const RedOrGreenColoringPeak = (props) => {
        const label = props.data.Peak;
        const txtColor = label[0] === '-' ? 'red-value-label' : 'green-value-label';
        return (
            <div>
                {props !== undefined ? (
                    <div className={txtColor}>{props.data.Peak}</div>
                ) : null}
            </div>
        )
    }

    const [colDefs, setColDefs] = useState([
        { field: "NameOfCallGroup", cellRenderer: CallGroupHtml, headerName: 'Call group' },
        { field: "DateOfCall", headerName: 'Date' },
        { field: "CalledAtMarketCap", headerName: 'CallMktCap' },
        { field: "CallEfc", headerName: 'CallEfc', cellRenderer: RedOrGreenColoringCallEf},
        { field: "Trace", headerName: 'Trace', cellRenderer: RedOrGreenColoringTrace },
        { field: "Peak", cellRenderer: RedOrGreenColoringPeak },
      ]);
    
    const populateSocialLinks = (data) => {
        let socialDataArray = [...resSocialData];
        //Twitter, Telegram, Discord, Instagram
        if(data.BioTwitter && data.BioTwitter !== '') {
            socialDataArray[0]['link'] = data.BioTwitter;
        }
        if(data.BioTelegram && data.BioTelegram !== '') {
            socialDataArray[1]['link'] = data.BioTelegram;
        }
        if(data.BioDiscord && data.BioDiscord !== '') {
            socialDataArray[2]['link'] = data.BioDiscord;
        }
        if(data.BioInstagram && data.BioInstagram !== '') {
            socialDataArray[3]['link'] = data.BioInstagram;
        }
        setResSocialData(socialDataArray);
    }
    const getData = () => {
        httpService.getTokenStatSearch(searchParam).then((res) => {
            if(res) {
                //console.log(res);
                setResData(res);
                populateSocialLinks(res);
            }
        })
    }

    const callGroupClick = (callerName) => {
        window.location.href = `/tokens/${callerName}`;
    }


    // useEffect(() => {
    //     const chartOut = document.getElementById('chart-out');
    //     if(resData && resData.TokenChain && chartOut && chartOut.children.length === 0) {
    //         createChart();
    //     }
    // }, [resData])

    const cellClickedListener =(e) => {
        window.location.href = `/tokens/${e.data.NameOfCallGroup}`;
    }
    
    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="token-details callers-list-page">
            <div>
                {resData && resData.TokenName ? (
                
                <div>
                    <div className="callers-top-info">
                        {/* <h1>{resData.TokenName}<small> {resData.TokenSymbol}</small></h1> */}
                        <div className="result-list-out flex-box">
                            <div>
                                <div className="sg-outline">
                                    <div className="tkn-top-info flex-box">
                                        <div>
                                            <img src={resData.TokenImage} alt="token image" />
                                        </div>
                                        <div>
                                            <h3 style={{'margin': '0'}}>{resData.TokenName}</h3>
                                            <span> {resData.TokenSymbol}/{resData.TokenChain} <span className="tkn-pr-usd"> {resData.PriceUSD} </span></span>
                                        </div>
                                    </div>
                                    <ul className='result-list'>
                                        <li>
                                            <span>Ca:</span>
                                            {/* <p><a href={resData.TokenLink} target="_blank">{resData.TokenHash}</a></p> */}
                                            <p>{modifiedTokenHash(resData.TokenHash)}</p>
                                            <div title="Copy Link"><SvgIcon copyText={resData.TokenHash} /></div>
                                        </li>
                                        <li>
                                            <span>MrkCap:</span>
                                            <p>{resData.CurrentMarketCap}</p>
                                        </li>
                                        <li>
                                            <span>MrkCap ATH:</span>
                                            <p>{resData.ATXMarketCap}</p>
                                        </li>
                                        <li>
                                            <span>Volume:</span>
                                            <p>{resData.Volume}</p>
                                        </li>
                                        <li>
                                            <span>Total Calls:</span>
                                            <p>{resData.TotalCalls}</p>
                                        </li>
                                    </ul>
                                    <div className="flex-box flex-grow-1 tkn-buttons">
                                        <div>
                                            <a type="button" className="btn btn-primary" href={resData.BuyLink} target="_blank">Buy</a>
                                        </div>
                                        <div className="a-center">
                                            <a type="button" className="btn btn-primary" href={resData.TokenLink} target="_blank">Scan</a>
                                        </div>
                                        <div className="a-right">
                                            <a type="button" className="btn btn-primary" href={resData.TokenChart} target="_blank">Chart</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="sg-outline">
                                    <p>
                                       {resData.BioToken}
                                    </p>
                                    <div className="flex-box align-right soc">
                                        <HeaderSocialLinks links={resSocialData} />
                                        <a type="button" className="btn btn-primary right" href={resData.UpdateBioButtonLink} target="_blank">Update Bio</a>
                                    </div>
                                </div>
                            </div>
                            <div id="chart-out">
                                {/* <ChartComponent /> */}
                                <div className="chart-out">
                                    <iframe src={`https://embedded.dexview.com/${resData.TokenChain}/${resData.TokenHash}`}  />
                                    {/* <iframe src={`https://www.dextools.io/widget-chart/en/${resData.TokenChain}/pe-light/${resData.TokenHash}`}  /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="callers-stat">
                        <h3>Best Calls</h3> 
                        <table style={{width: '100%'}} className="callers-stat-table">
                            <tbody>
                                {resData.TokenStatisticData && resData.TokenStatisticData.length > 0 ? (
                                    <>
                                        {resData.TokenStatisticData.map((item, i) => {
                                            return (
                                                <tr key={i} onClick={() => callGroupClick(item.NameOfCallGroup)}>
                                                    <td className="td-img"><span className="call-group-img"><img alt="Call group image" src={`/callgrimages/${item.CallGroupImage.split('/').pop()}`} /></span></td>
                                                    <td>{item.NameOfCallGroup} <br />{item.DateOfCall}</td>
                                                    <td>Called at <br />Reached</td>
                                                    <td>{item.CalledAtMarketCap} <br />{item.ReachedMarketCap}</td>
                                                    <td>{item.Peak}</td>
                                                </tr>
                                            )
                                        })}
                                    </>
                                ) : null}
                            </tbody>
                        </table>
                    </div> */}
                    {resData.TokenStatisticData && resData.TokenStatisticData.length > 0 ? (
                        <ReactGrid 
                            tooltipInteraction={true} 
                            columns={colDefs} 
                            sortable={true} 
                            rowData={resData.TokenStatisticData} 
                            errorMessage={''} 
                            // defaultColDef={{'flex': 1}} 
                            cellClickedListener={cellClickedListener}
                        />
                    ) : null}
                </div>
                ) : null}
            </div>
        </div>
    )
}

export default CallersList;