import React, { useRef, useMemo, useCallback} from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import { useNavigate } from 'react-router-dom';
import { getDefaultTheme } from "../Utils/utils";
import * as httpService from '../services/HTTPService';

const ReactGrid = (props) => {
  //const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
    const gridRef = useRef(); // Optional - for accessing Grid's API

  
    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo( ()=> ({
        sortable: props.sortable
      }));
  
    // Example of consuming Grid Event
    const cellClickedListener = useCallback( e => {
      console.log(e.column.colId);
      if(e.column.colId !== "BuyLink" && props.onRowSelected && props.gridName !== 'Top5CallersGrid' && e.column.colId !== 'TokenName' && e.column.colId !== 'FullTokenName') {
        const callGroup = e.data.NameOfCallGroup;
        const positionOfCallGroup = e.data.PositionOfCallGroup;
        const paramName = encodeURIComponent(callGroup);
        const url = `/tokens/${paramName}`
        window.location.href = url;
        // httpService.getSingleItem(callGroup).then((res) => {
        //   if(res) {
        //     //navigate(`/tokens/${callGroup}`, { state: {rowData: res, tokenName: e.data.TokenName, callGroup: callGroup, positionOfCallGroup: positionOfCallGroup, tokenData: e.data  } });
        //     window.location.href = `/tokens/${callGroup}`;
        //   }
        // })
        // .catch(err => {
        //   if(err) {
        //     console.log(err)
        //     //setErrorMessage(err);
        //   }
        // })
      }

      if(props.gridName && props.gridName === 'Top5CallersGrid') {
        window.location.href = `/tokens/${e.data.Name}`;
      }
      if(e.column.colId === 'TokenName' || e.column.colId === 'FullTokenName') {
        window.location.href = `/callers/${e.data.TokenHash}`;
      }
      if(e.event.target.classList.value.indexOf('top-grid-name') !== -1) {
        const hash = e.data.TokenHash;
        const url = `/callers/${hash}`;
        window.location.href = url;
      }
    }, []);

    // const onRowSelected = (e) => {
    //   if(props.onRowSelected) {
    //     console.log(e);
    //     const callGroup = e.data.NameOfCallGroup;
    //     const positionOfCallGroup = e.data.PositionOfCallGroup;
    //     httpService.getSingleItem(callGroup).then((res) => {
    //       if(res) {
    //         navigate(`/tokens/${callGroup}`, { state: {rowData: res, tokenName: e.data.TokenName, callGroup: callGroup, positionOfCallGroup: positionOfCallGroup, tokenData: e.data   } });
    //       }
    //     })
    //     .catch(err => {
    //       if(err) {
    //         console.log(err)
    //         //setErrorMessage(err);
    //       }
    //     })
    //   }
    // }

    const isFullWidthRow = useCallback((params) => {
      // in this example, we check the fullWidth attribute that we set
      // while creating the data. what check you do to decide if you
      // want a row full width is up to you, as long as you return a boolean
      // for this method.
      return params.rowNode.data ? params.rowNode.data.fullWidth : false;
    }, []);

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const onSortChanged = (e) => {
      e.api.refreshCells();
    }
    return (
      <div>
        <div style={containerStyle}>
            <div className={getDefaultTheme() === 'light' ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} style={gridStyle} >
              <AgGridReact
                ref={gridRef} // Ref for accessing Grid's API
                rowData={props.rowData} // Row Data for Rows
                columnDefs={props.columns} // Column Defs for Columns
                defaultColDef={props.defaultColDef || defaultColDef} // Default Column Properties
                maintainColumnOrder="true"
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection='single' // Options - allows click selection of rows
                //onRowSelected={onRowSelected}
                onCellClicked={props.cellClickedListener || cellClickedListener} // Optional - registering for Grid Event
                isFullWidthRow={props.isFullWidthRow || isFullWidthRow}
                embedFullWidthRows={true}
                tooltipShowDelay={0}
                tooltipInteraction={true}
                tooltipHideDelay={20000}
                rowHeight={props.rowHeight || 65}
                onSortChanged={onSortChanged}
                suppressDragLeaveHidesColumns={true}
              />
            </div>
        </div>

        {/* On div wrapping Grid a) specify theme CSS Class Class and b) sets Grid size */}
        {/* <div style={containerStyle}>
          {props.errorMessage !== '' ? (
            <div className='error'>
              <h3>Something went wrong. Please try again.</h3>
            </div>
          ) : (
            <div className={getDefaultTheme() === 'light' ? 'ag-theme-alpine' : 'ag-theme-alpine-dark'} style={gridStyle} >
              <AgGridReact
                ref={gridRef}
                rowData={props.rowData}
                columnDefs={props.columns}
                defaultColDef={defaultColDef}
                maintainColumnOrder="true"
                animateRows={true}
                rowSelection='single'
                onCellClicked={cellClickedListener}
                isFullWidthRow={isFullWidthRow}
                embedFullWidthRows={true}
                tooltipShowDelay={0}
                tooltipInteraction={true}
                tooltipHideDelay={20000}
                rowHeight={65}
                onSortChanged={onSortChanged}
              />
            </div>
          )}
          
        </div> */}
      </div>
    );
}

export default ReactGrid;