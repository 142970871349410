import { gridColumns } from "./gridColumns";
import { callersGridColumns } from "./callersGridColumns";


const isBrowserDefaultDark = () => window.matchMedia('(prefers-color-scheme: dark)').matches;
export const getDefaultTheme = () => {
    const localStorageTheme = localStorage.getItem('default-theme');
   // const browserDefault = isBrowserDefaultDark() ? 'dark' : 'light';
    const browserDefault = isBrowserDefaultDark() ? 'dark' : 'dark';
    return localStorageTheme || browserDefault;
};

const isNumber = (char) => {
    return /^\d$/.test(char);
}

export const modifiedTokenHash = (str) => {
    let string = '';
    const start = str.substr(0, 5);
    const end = str.substr(string.length - 4);
    string = start + '...' + end;
    return string;
}

export const customSort = (valueA, valueB, nodeA, nodeB, isDescending) => {
    // First: check the values at the end of the string for char like % or k... etc. and remove it
    const lastvalueACharIsNotNumber = !isNumber(valueA[valueA.length - 1]);
    const lastvalueBCharIsNotNumber = !isNumber(valueB[valueB.length - 1]);
    
    let a = valueA;
    //console.log(valueA.charAt(valueA.length - 1))
    //console.log(isNumber(valueA[valueA.length - 1]))
    let b = valueB;
    // remove the last char if is not integer
    if(lastvalueACharIsNotNumber) {
        a = a.slice(0, -1);
    }
    if(lastvalueBCharIsNotNumber) {
        b = b.slice(0, -1);
    }
    // remove '+' at the begining
    if(a.charAt(0) === '+') {
        a = a.slice(1);
    }
    let aNum = parseFloat(a);
    if(b.charAt(0) === '+') {
        b = b.slice(1);
    }
    let bNum = parseFloat(b);
    if (aNum === bNum) return 0;
    return (aNum > bNum) ? 1 : -1;
}


export const createGridColumns = (paramsArray, isCallersPage) => {
    // paramsArray must be an array of desired columns e.g. ['TokenName', 'BuyLink'...]
    let columns = [];
    let columnArr = gridColumns;
    if(isCallersPage) {
        columnArr = callersGridColumns;
    }
    if(paramsArray && paramsArray.length > 0) {
        for(var i = 0; i < paramsArray.length; i++) {
            // eslint-disable-next-line no-loop-func
            columnArr.forEach((item) => {
                const currentItem = paramsArray[i];
                if(currentItem === item.field) {
                    columns.push(item);
                }
            })
        }
        return columns;
    }
}